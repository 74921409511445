import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ButtonWithSpinner from "../ButtonWithSpinner";
import toastError from "../../errors/toastError";
import useQueues from "../../hooks/useQueues";
import useWhatsApps from "../../hooks/useWhatsApps";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../Can";
import { ContactsFilter } from "../ContactsFilter";

const useStyles = makeStyles((theme) => ({
  maxWidth: {
    width: "100%",
  },
  searchInput: {
    color: theme.palette.primary.main,
    marginLeft: 6,
    marginRight: 6,
    marginBottom: 20,
    alignSelf: "center",
  },
  imageInput: {
    color: theme.palette.primary.main,
    marginLeft: 6,
    marginRight: 6,
    alignSelf: "center",
  },
}));

const filterOptions = createFilterOptions({
  trim: true,
});

const CreateGroupModal = ({
  modalOpen,
  onClose,
  ticketid,
  ticketWhatsappId,
}) => {
  const history = useHistory();
  const [options, setOptions] = useState([]);
  const [queues, setQueues] = useState([]);
  const [allQueues, setAllQueues] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedQueue, setSelectedQueue] = useState("");
  const [selectedWhatsapp, setSelectedWhatsapp] = useState(ticketWhatsappId);
  const [selectedImage, setSelectedImage] = useState(ticketWhatsappId);
  const classes = useStyles();
  const { findAll: findAllQueues } = useQueues();
  const { loadingWhatsapps, whatsApps } = useWhatsApps();
  const [selectedName, setSelectedName] = useState([]);
  const [file, setFile] = useState();

  const { user: loggedInUser } = useContext(AuthContext);

  useEffect(() => {
    const loadQueues = async () => {
      const list = await findAllQueues();
      setAllQueues(list);
      setQueues(list);
    };
    loadQueues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!modalOpen || searchParam.length < 3) {
      setLoading(false);
      return;
    }
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchContacts = async () => {
        try {
          const { data } = await api.get("contacts", {
            params: { searchParam },
          });
          setOptions(data.contacts);
          setLoading(false);
        } catch (err) {
          setLoading(false);
          toastError(err);
        }
      };

      fetchContacts();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, modalOpen]);

  const handleClose = () => {
    onClose();
    setSearchParam("");
    setSelectedUser(null);
  };

  const handleSaveTicket = async (e) => {
    e.preventDefault();

    setLoading(true);
    try {
      let data = {};

      const formData = new FormData();
      formData.append("medias", file);
      formData.append("arrayUsers", selectedUser);
      formData.append("name", selectedName);
      formData.append("queueId", selectedQueue);
      formData.append("whatsappId", selectedWhatsapp);

      var ticketId = await api.post(`/group/create`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      setLoading(false);
      //	history.push(`/tickets/${ticketId}`);
      handleClose();
    } catch (err) {
      setLoading(false);
      toastError(err);
    }
  };

  const handleSelectedTags = (selecteds) => {
    const tags = selecteds.map((t) => t.id);
    setSelectedUser(tags);
  };

  return (
    <Dialog open={modalOpen} onClose={handleClose} maxWidth="lg" scroll="paper">
      <form onSubmit={handleSaveTicket}>
        <DialogTitle id="form-dialog-title">Criar Grupo WhatsApp</DialogTitle>
        <DialogContent dividers>
          <FormControl variant="outlined" className={classes.maxWidth}>
            <InputLabel>Digite o nome do Grupo</InputLabel>
            <Input
              fullWidth
              type="text"
              className={classes.searchInput}
              value={selectedName}
              onChange={(e) => setSelectedName(e.target.value)}
            />
          </FormControl>
          <FormControl className={classes.maxWidth}>
            <Typography variant="body1">Imagem do Grupo</Typography>
            <Input
              fullWidth
              type="file"
              accept="image/*"
              className={classes.imageInput}
              filename={file}
              onChange={(e) => setFile(e.target.files[0])}
              placeholder="Imagem do Grupo"
            />
          </FormControl>
          <FormControl
            variant="outlined"
            className={classes.maxWidth}
            style={{ marginTop: 20 }}
          >
            <ContactsFilter onFiltered={handleSelectedTags} />
          </FormControl>
          {/*<FormControl variant="outlined" className={classes.maxWidth}  style={{ marginTop: 20 }}>
						<InputLabel>Escolhe o setor</InputLabel>
						<Select
							value={selectedQueue}
							onChange={(e) => setSelectedQueue(e.target.value)}
							label="Escolhe o setor"
						>
							<MenuItem value={''}>&nbsp;</MenuItem>
							{queues.map((queue) => (
								<MenuItem key={queue.id} value={queue.id}>{queue.name}</MenuItem>
							))}
						</Select>
					</FormControl>*/}
          {/*<Can
						role={loggedInUser.profile}
						perform="ticket-options:transferWhatsapp"
						yes={() => (!loadingWhatsapps &&
							<FormControl variant="outlined" className={classes.maxWidth} style={{ marginTop: 20 }}>
								<InputLabel>Escolha a conexão</InputLabel>
								<Select
									value={selectedWhatsapp}
									onChange={(e) => setSelectedWhatsapp(e.target.value)}
									label="Escolha a conexão"
								>
									{whatsApps.map((whasapp) => (
										<MenuItem key={whasapp.id} value={whasapp.id}>{whasapp.name}</MenuItem>
									))}
								</Select>
							</FormControl>
						)}
					/> */}
          <FormControl
            variant="outlined"
            className={classes.maxWidth}
            style={{ marginTop: 20 }}
          >
            <InputLabel>Escolha a conexão</InputLabel>
            <Select
              value={selectedWhatsapp}
              onChange={(e) => setSelectedWhatsapp(e.target.value)}
              label="Escolha a conexão"
            >
              {whatsApps.map((whasapp) => (
                <MenuItem key={whasapp.id} value={whasapp.id}>
                  {whasapp.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="secondary"
            disabled={loading}
            variant="outlined"
          >
            {i18n.t("transferTicketModal.buttons.cancel")}
          </Button>
          <ButtonWithSpinner
            variant="contained"
            type="submit"
            color="primary"
            loading={loading}
          >
            Criar Grupo
          </ButtonWithSpinner>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateGroupModal;
